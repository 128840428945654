import React, { useEffect, useState } from 'react';
import './appointmentModal.css'
import Modal from 'react-modal';
import Select from 'react-select';
import { BASE_URL } from '../../../constants';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../../Store/App/userProfile';
import Loader from '../../Loader/Loader';
import { selectCredits, updateCredits } from '../../../Store/App/Header';

const AppointmentModal = ({ isOpen, onClose, date }) => {

    const userToken = localStorage.getItem('frotaToken');

    const userProfile = useSelector(selectUserProfile);
    const credits = useSelector(selectCredits);
    const dispatch = useDispatch()

    const [selectedService, setSelectedService] = useState(null);
    const [selectedCoach, setSelectedCoach] = useState(null);
    const [services, setServices] = useState(null)
    const [coaches, setCoaches] = useState(null)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    const [isLoading, setIsLoading] = useState(true)

    // Sample data for services, coaches, and time slots
    // const services = [
    //     { value: 'service1', label: 'Service 1' },
    //     { value: 'service2', label: 'Service 2' },
    //     { value: 'service3', label: 'Service 3' },
    // ];

    // const coaches = [
    //     { value: 'coach1', label: 'Coach 1' },
    //     { value: 'coach2', label: 'Coach 2' },
    //     { value: 'coach3', label: 'Coach 3' },
    // ];

    const timeSlots = [
        { value: '06:00 - 07:00', label: '06:00 - 07:00' },
        { value: '06:30 - 07:30', label: '06:30 - 07:30' },
        { value: '07:00 - 08:00', label: '07:00 - 08:00' },
        { value: '07:30 - 08:30', label: '07:30 - 08:30' },
        { value: '08:00 - 09:00', label: '08:00 - 09:00' },
        { value: '08:30 - 09:30', label: '08:30 - 09:30' },
        { value: '09:00 - 10:00', label: '09:00 - 10:00' },
        { value: '09:30 - 10:30', label: '09:30 - 10:30' },
        { value: '10:00 - 11:00', label: '10:00 - 11:00' },
        { value: '10:30 - 11:30', label: '10:30 - 11:30' },
        { value: '11:00 - 12:00', label: '11:00 - 12:00' },
        { value: '11:30 - 12:30', label: '11:30 - 12:30' },
        { value: '12:00 - 13:00', label: '12:00 - 13:00' },
        { value: '12:30 - 13:30', label: '12:30 - 13:30' },
        { value: '13:00 - 14:00', label: '13:00 - 14:00' },
        { value: '13:30 - 14:30', label: '13:30 - 14:30' },
        { value: '14:00 - 15:00', label: '14:00 - 15:00' },
        { value: '14:30 - 15:30', label: '14:30 - 15:30' },
        { value: '15:00 - 16:00', label: '15:00 - 16:00' },
        { value: '15:30 - 16:30', label: '15:30 - 16:30' },
        { value: '16:00 - 17:00', label: '16:00 - 17:00' },
        { value: '16:30 - 17:30', label: '16:30 - 17:30' },
        { value: '17:00 - 18:00', label: '17:00 - 18:00' },
        { value: '17:30 - 18:30', label: '17:30 - 18:30' },
        { value: '18:00 - 19:00', label: '18:00 - 19:00' },
        { value: '18:30 - 19:30', label: '18:30 - 19:30' },
        { value: '19:00 - 20:00', label: '19:00 - 20:00' },
        { value: '19:30 - 20:30', label: '19:30 - 20:30' },
        { value: '20:00 - 21:00', label: '20:00 - 21:00' },
        { value: '20:30 - 21:30', label: '20:30 - 21:30' },
        { value: '21:00 - 22:00', label: '21:00 - 22:00' },
        { value: '21:30 - 22:30', label: '21:30 - 22:30' },
        { value: '22:00 - 23:00', label: '22:00 - 23:00' },
        { value: '22:30 - 23:30', label: '22:30 - 23:30' },
        { value: '23:00 - 23:59', label: '23:00 - 00:00' },
        // Add more time slots as needed
    ];


    const handleServiceChange = (selectedOption) => {
        setSelectedService(selectedOption);
    };

    const handleCoachChange = (selectedOption) => {
        setSelectedCoach(selectedOption);
    };

    const handleTimeSlotChange = (selectedOption) => {
        setSelectedTimeSlot(selectedOption);
    };

    const handleSubmit = async () => {
        // Handle form submission
        console.log('Selected Date:', date);
        console.log('Selected Service:', selectedService);
        console.log('Selected Coach:', selectedCoach);
        console.log('Selected Time Slot:', selectedTimeSlot);

        if (!selectedService) {
            toast.error('Please Select a Service.');
            return
        }

        if (!selectedCoach) {
            toast.error('Please Select a Coach.');
            return
        }

        if (!selectedTimeSlot) {
            toast.error('Please Select a Time Slot.');
            return
        }

        const timeRange = selectedTimeSlot.value;
        const [startTime, endTime] = timeRange.split(" - ");

        console.log("Start Time:", startTime); // Output: Start Time: 1:30 PM
        console.log("End Time:", endTime);     // Output: End Time: 2:30 PM


        const requestBody = {
            coachId: selectedCoach.value,
            specialityId: selectedService.value,
            startTime: startTime,
            endTime: endTime,
            scheduledAt: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).toISOString()
        };
        // const requestBody = {
        //     "coachId": 7,
        //     "startTime": "02:00",
        //     "endTime": "03:00",
        //     "scheduledAt": "2024-02-12"
        // };

        setIsLoading(true);

        try {
            const response = await fetch(`${BASE_URL}student/appointments/create`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const json = await response.json()
                // console.log(json)
                if (json.data?.message === "Insufficient credits") {
                    toast.error('You have Insufficient Credits.')
                    // toast.error(json.message);
                } else if (json.data?.message?.toLowerCase().startsWith("you already have an appointment in this time slot")) {
                    toast.error(json.data?.message);
                } else {
                    toast.error('Error: Please Try Again');
                }
                setIsLoading(false)
                throw new Error('Network response was not ok');
            }
            const json = await response.json();

            if (json.success) {
                toast.success("Appointment Request Sent.")
                if (credits > 0) {
                    dispatch(updateCredits(credits - 1));
                }
            }

            // console.log('Success:', json);
        } catch (error) {
            console.error('Error:', error);
        }
        setIsLoading(false);

        onClose(); // Close the modal after submission
    };

    const getCoachData = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${BASE_URL}coach/?id=${selectedService?.value}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const json = await response.json();
            setCoaches(json.data);

        } catch (error) {
            // Handle fetch or parsing errors
            console.error("Error:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getCoachData();
        console.log(date)
    }, [selectedService]);


    const fetchSpecialities = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}speciality`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            if (response.ok) {
                const json = await response.json();
                setServices(json.data);
                // console.log(json.data)
            } else {
                console.error('Failed to fetch specialities:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching specialities:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchSpecialities();
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Appointment Modal"
            className="AppointmentModal" // Add custom class for animation
            overlayClassName="AppointmentModal-overlay"
        >
            <Loader isLoading={isLoading} />

            <h2>Select Booking Details</h2>
            <p>Date: {date.toDateString()}</p>

            <div className='select-field'>
                <label className='select-label'>Select Service:</label>
                <Select
                    value={selectedService}
                    onChange={handleServiceChange}
                    options={services?.map(option => ({
                        value: option.id,
                        label: option.name,
                        imageUrl: option.mediaUrl
                    }))}
                    styles={selectStyles}
                />
            </div>

            {selectedService && (
                <div className='select-field'>
                    <label className='select-label'>Select Coach:</label>
                    <Select
                        value={selectedCoach}
                        onChange={handleCoachChange}
                        options={coaches?.map(option => ({
                            value: option.id,
                            label: option.name,
                            imageUrl: option.avatar
                        }))}
                        // components={{ Option: customOption }}
                        styles={selectStyles}
                    />
                </div>)}

            {selectedCoach && (
                <div className='select-field'>
                    <label className='select-label'>Select Time Slot:</label>
                    <Select
                        value={selectedTimeSlot}
                        onChange={handleTimeSlotChange}
                        options={timeSlots}
                        styles={selectStyles}
                    />
                </div>)}

            <div className="modal-btn-container">
                <button className='cancel-button' onClick={onClose}>Cancel</button>

                <button className='modal-button' onClick={handleSubmit}>Book Appointment</button>
            </div>
        </Modal>
    );

};

export const selectStyles = {
    control: (styles, { isFocused, isDisabled }) => ({
        ...styles,
        backgroundColor: isFocused ? '#FFF' : 'white', // Change background color based on focus
        borderColor: isFocused ? 'var(--red)' : '#ced4da', // Change border color based on focus
        borderSize: 1,
        boxShadow: isFocused ? '0 0 0 1px var(--red)' : '', // Add box shadow for focus
        cursor: isDisabled ? 'not-allowed' : 'default', // Set cursor based on disabled state
        '&:hover': {
            borderColor: isFocused ? 'var(--red)' : '#adb5bd', // Change border color on hover
        },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? 'var(--red)' : isFocused ? '#3331' : 'transparent', // Change background color based on selection and focus
            color: isSelected ? '#FFF' : '', // Change text color based on selection
            cursor: isDisabled ? 'not-allowed' : 'default', // Set cursor based on option state
            '&:hover': {
                backgroundColor: isSelected ? 'var(--red)' : '#f8f9fa', // Change background color on hover
                color: isSelected ? '#FFF' : '', // Change text color on hover
            },
        };
    },
    menu: (styles) => ({ ...styles, zIndex: 9999 }), // Increase z-index to ensure dropdown menu appears on top
};

const customOption = ({ data, ...props }) => (
    <div {...props}>
        <img src={data.imageUrl} alt={data.label} style={{ marginRight: '10px', width: '20px', height: '20px' }} />
        {data.label}
    </div>
);

export default AppointmentModal;
