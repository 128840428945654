import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { BASE_URL } from '../../../constants';
import { toast } from 'react-toastify';
import Loader from '../../Loader/Loader';
import { convertTo12HourFormat } from '../../Admin/Statistics/AppointmentsStatsList';
import { useDispatch, useSelector } from 'react-redux';
import { selectCredits, updateCredits } from '../../../Store/App/Header';
import { selectUserProfile } from '../../../Store/App/userProfile';

const BookingCard = ({ booking, refresh, setRefresh }) => {
    const { id, scheduledAt, startTime, endTime, status, charges, student, coach, speciality, comments, isStudent } = booking;

    const dispatch = useDispatch();
    const credits = useSelector(selectCredits);
    const userProfile = useSelector(selectUserProfile);

    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmCancelModal, setIsConfirmCancelModal] = useState(false)
    const [isConfirmEndClassModal, setIsConfirmEndClassModal] = useState(false)
    const [showEndClassButton, setShowEndClassButton] = useState(false)
    const [showCancelClassButton, setShowCancelClassButton] = useState(false)
    const userToken = localStorage.getItem('frotaToken');

    const getStatusColor = () => {
        switch (status.toLowerCase()) {
            case 'approved':
                return '#2bb700';
            case 'declined' || 'cancelled':
                return 'var(--red)';
            case 'ended':
                return '#0080ff';
            default:
                return '#ffbf00';
        }

    };

    // Function to format status text
    const formatStatusText = () => {
        switch (status.toLowerCase()) {
            case 'approved':
                return <span style={{ color: 'var(--approved-color)', fontWeight: 'bold', fontStyle: 'italic' }}>{status.toUpperCase()}</span>;
            case 'declined':
                return <span style={{ color: 'var(--red)', fontWeight: 'bold', fontStyle: 'italic' }}>{status.toUpperCase()}</span>;
            case 'cancelled':
                return <span style={{ color: 'var(--red)', fontWeight: 'bold', fontStyle: 'italic' }}>{status.toUpperCase()}</span>;
            case 'ended':
                return <span style={{ color: '#0080ff', fontWeight: 'bold', fontStyle: 'italic' }}>{status.toUpperCase()}</span>;
            default:
                return <span style={{ color: '#ffbf00', fontWeight: 'bold', fontStyle: 'italic' }}>{status.toUpperCase()}</span>;
        }
    };

    const handleEndClass = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}coach/class/end/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
            });
            if (response.ok) {
                toast.success('Class Ended.');
                setIsConfirmEndClassModal(false);

                setRefresh(!refresh);
            }
        } catch (error) {
            console.error('Error ending class:', error.message);
            toast.error("Error! Please Try Again")
        }
        setIsLoading(false);
    };

    const handleCancelClass = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}${userProfile?.roles[0]}/appointments/cancel/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to cancel appointment');
            }

            const json = await response.json();
            if (json.success) {
                toast.success('Appointment Request Cancelled.');
                setIsConfirmCancelModal(false);

                if (userProfile?.roles[0] === 'student') {
                    if (isBefore24HourAppointment()) {
                        dispatch(updateCredits(credits + 1));
                    }
                }

                setRefresh(!refresh);
            }
            // Handle response data here if needed
        } catch (error) {
            console.error('Error cancelling appointment:', error.message);
            // Handle error gracefully
        }
        setIsLoading(false);
    };

    const isBefore24HourAppointment = () => {
        const now = new Date();
        const scheduledDateTime = new Date(`${scheduledAt}T${startTime}`);
        // const date24HoursBefore = new Date(scheduledDateTime.getTime() - 24 * 60 * 60 * 1000);
        const date24HoursBefore = new Date(scheduledDateTime.getTime());

        return now < date24HoursBefore;
    };

    const isAfterCurrentDateTime = () => {
        const now = new Date();
        const scheduledDateTime = new Date(`${scheduledAt}T${startTime}`);

        return now > scheduledDateTime;
    };

    useEffect(() => {


        // const response = isBefore24HourAppointment()
        // console.log(response)

        setShowEndClassButton(isAfterCurrentDateTime());
        setShowCancelClassButton(isBefore24HourAppointment())

    }, [])


    return (
        <div className="booking-card">
            <Loader isLoading={isLoading} />
            <div className="booking-info">
                <div className="booking-details">
                    <p className="booking-id"><strong>Booking ID: {id}</strong> </p>
                    <p className="scheduled-time"><strong>Class:</strong> {speciality?.name}</p>
                    <p className="scheduled-time"><strong>Date:</strong> {scheduledAt}</p>
                    <p className="time-range"><strong>Time:</strong> {convertTo12HourFormat(startTime)} - {convertTo12HourFormat(endTime)}</p>
                    <p className="status"><strong>Status:</strong> <span style={{ color: getStatusColor(), fontWeight: 'bold', fontStyle: 'italic' }}>{formatStatusText()}</span></p>
                    {comments?.length > 0 && (
                        <>
                            <p className="charges"><strong>Comments from Coach:</strong></p>
                            <p className='comments'>{comments}</p>
                        </>)}
                    {/* <p className="charges"><strong>Charges:</strong> {charges}</p> */}
                </div>
                <div className="student-info">
                    <h6 style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>{!isStudent ? 'Student' : 'Coach'}</h6>
                    {/* <img className="avatar" src={student.avatar} alt="Student Avatar" /> */}

                    {!isStudent ?
                        (< div className="image-container my-2">
                            {!student.avatar ?
                                <div className='no-profile' style={{ width: '80px', height: '80px' }}>
                                    <FontAwesomeIcon icon={faUser} size='2xs' />
                                </div>
                                : <img src={student.avatar} alt="Uploaded" style={{ width: '80px', height: '80px' }} />}
                        </div>) :
                        (<div className="image-container my-2">
                            {!coach?.avatar ?
                                <div className='no-profile' style={{ width: '80px', height: '80px' }}>
                                    <FontAwesomeIcon icon={faUser} size='2xs' />
                                </div>
                                : <img src={coach?.avatar} alt="Uploaded" style={{ width: '80px', height: '80px' }} />}
                        </div>)}
                    <p className="student-name mt-2 mb-2" style={{ textAlign: 'center' }}>{!isStudent ? student.name : coach?.name}</p>

                    {!isStudent && showEndClassButton && status?.toLowerCase() === 'approved' && (
                        <button className='cancel-request-btn' onClick={() => setIsConfirmEndClassModal(true)}>End Class</button>)}

                    {showCancelClassButton && (status?.toLowerCase() === 'pending' || status?.toLowerCase() === 'approved') && (
                        <button className='cancel-request-btn' onClick={() => setIsConfirmCancelModal(true)}>Cancel Class</button>)}

                    {isConfirmCancelModal && (
                        <ConfirmationModal
                            isOpen={isConfirmCancelModal}
                            onRequestClose={() => setIsConfirmCancelModal(false)}
                            onConfirm={handleCancelClass}
                            message="Are you sure you want to Cancel this Request?"
                        />
                    )}

                    {isConfirmEndClassModal && (
                        <ConfirmationModal
                            isOpen={isConfirmEndClassModal}
                            onRequestClose={() => setIsConfirmEndClassModal(false)}
                            onConfirm={handleEndClass}
                            message="Are you sure you want to End this Class?"
                        />
                    )}
                </div>
            </div>
        </div >
    );
};

export default BookingCard;
