import React, { useEffect, useState } from 'react';
import './sharedCalendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppointmentModal from '../Modals/AppointmentModal/AppointmentModal';
import { BASE_URL } from '../../constants';
import AppointmentDetailsModal from '../Modals/AppointmentDetails/AppointmentDetails';
import { useSelector } from 'react-redux';
import { selectRefreshCoach } from '../../Store/App/Coach/coach';

const localizer = momentLocalizer(moment);

const SharedCalendar = ({ isStudent }) => {
    const refreshCoach = useSelector(selectRefreshCoach);
    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [eventsData, setEventsData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBookings();
    }, [refreshCoach]);

    const fetchBookings = async () => {
        try {
            const userToken = localStorage.getItem('frotaToken');
            const response = await fetch(`${BASE_URL}coach/all-appointments`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const { data } = await response.json();
            const appointments = data || [];

            const events = appointments
                .filter(appointment => appointment.status === 'approved')
                .map(appointment => ({
                    id: appointment.id,
                    title: appointment?.speciality?.name || 'Event',
                    start: `${appointment.scheduledAt} ${appointment.startTime}`,
                    end: `${appointment.scheduledAt} ${appointment.endTime}`,
                    student: appointment.student,
                    coach: appointment.coach || {},
                    speciality: appointment.speciality,
                    status: appointment.status,
                    date: `${appointment.scheduledAt}`,
                    time: `${appointment.startTime.substring(0, 5)}-${appointment.endTime.substring(0, 5)}`,
                }));

            setEventsData(events);
        } catch (error) {
            console.error('Error fetching bookings:', error);
            setError('Failed to fetch bookings. Please try again later.');
        }
    };

    const handleSelectSlot = (slotInfo) => {
        if (!isStudent) return;

        const now = new Date();
        const slotDate = new Date(slotInfo.start);

        // Strip the time part to focus only on the date
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const slotDay = new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate());

        // Logging for debugging
        console.log('Today -->', today);
        console.log('Slot date -->', slotDay);

        // Check if the slot date is earlier than today
        if (slotDay < today) {
            console.log('The slot date is earlier than today.');
            return;
        }

        console.log("slotInfo: ", slotInfo)

        setSelectedDate(slotInfo.start);
        setIsOpenModal(true);
    };

    const handleSelectEvent = (event) => {
        setSelectedAppointment(event);
        setIsOpenDetailsModal(true);
    };

    return (
        <div className='calendar-container'>
            <h2 className='calender-title'>SHARED CALENDAR</h2>
            {error && <div className="error-message">{error}</div>}
            <Calendar
                localizer={localizer}
                selectable
                events={eventsData}
                startAccessor={(event) => { return new Date(event.start) }}
                endAccessor={(event) => { return new Date(event.end) }}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                tooltipAccessor={(event) => (
                    `Class: ${event.title} \nStatus: ${event.status} \nStudent: ${event.student?.name} \nCoach: ${event.coach?.name}`
                )}
                eventPropGetter={(event, start, end, isSelected) => {
                    let backgroundColor = isSelected ? 'var(--hover-color)' : 'var(--red)';
                    let borderColor = isSelected ? 'var(--hover-color)' : 'var(--red)';
                    return { style: { backgroundColor, borderColor } };
                }}
                dayPropGetter={(date) => {
                    return { className: 'custom-day' };
                }}
            />
            {isOpenModal && (
                <AppointmentModal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    date={selectedDate}
                />
            )}

            {isOpenDetailsModal && (
                <AppointmentDetailsModal
                    isOpen={isOpenDetailsModal}
                    onClose={() => setIsOpenDetailsModal(false)}
                    appointment={selectedAppointment}
                />
            )}
        </div>
    );
};

export default SharedCalendar;
